import request from "@/utils/request"

const moduleName = "/consultation"

export default {
	// 获取咨询列表
	apiGetConsultList: data =>
		request(`${moduleName}/fetch`, {
			method: "get",
			params: data
		}),
	// 咨询申请
	apiConsultAdd: data =>
		request(`${moduleName}/add`, {
			method: "post",
			body: data
		}),
	// 咨询申请, 不需要token
	apiConsultAddWithOutToken: data =>
		request(`${moduleName}/add-by-login-page`, {
			method: "post",
			body: data
		}),
	// 获取咨询指派成员
	apiGetConsultAppoint: () =>
		request(`${moduleName}/fetchassignuser`, {
			method: "get"
		}),
	// 删除咨询
	apiDelConsult: id =>
		request(`${moduleName}/del/${id}`, {
			method: "delete"
		}),
	// 指派任务给某人
	apiApprove: data =>
		request(`${moduleName}/assign`, {
			method: "put",
			body: data
		}),
	// 修改指派人员
	updateApprove: data =>
		request(`${moduleName}/update-assign`, {
			method: "put",
			body: data
		}),
	// 咨询详情查看
	apiGetConsultDetail: data =>
		request(`${moduleName}/findbyid`, {
			method: "get",
			params: data
		}),
	// 咨询反馈，暂存
	apiFeedbackSave: data =>
		request(`${moduleName}/serve-modules/save-answer`, {
			method: "post",
			body: data
		}),
	// 咨询反馈，提交
	apiFeedbackCommit: data =>
		request(`${moduleName}/serve-modules/commit-answer`, {
			method: "post",
			body: data
		}),
	apiExportList: data =>
		request(`${moduleName}/list`, {
			method: "get",
			params: data
		}),
	// 获取咨询预约设置
	apiAppointment: () =>
		request(`${moduleName}/appointment-setting`, {
			method: "get"
		}),
	// 更新咨询预约设置
	updateAppointment: data =>
		request(`${moduleName}/appointment-setting`, {
			method: "put",
			body: data
		}),
	// 获取咨询预约剩余次数
	apiAppointmentTimes: data =>
		request(`${moduleName}/appointment-times-left`, {
			method: "get",
			params: data
		}),
	// 获取可回复某模块信息的人员列表
	apiGetUserPermissionIds: data =>
		request(`${moduleName}/serve-modules/distribute/users`, {
			method: "post",
			body: data
		}),
	// 咨询满意度回复
	apiSatisfactionReply: data =>
		request(`${moduleName}/serve-modules/satisfy`, {
			method: "post",
			body: data
		}),
	// 分配提交
	apiDistribute: data =>
		request(`${moduleName}/serve-modules/distribute`, {
			method: "post",
			body: data
		})
}
