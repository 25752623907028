<template>
	<div class="add-consult-list-wrap">
		<div class="modal-header">
			<h2>{{ titleTxt }}</h2>
		</div>
		<div class="modal-content">
			<Spin fix v-if="loading"></Spin>
			<Form
				v-if="isOk"
				:model="formData"
				ref="addConsultForm"
				label-position="top"
				:rules="rules"
				class="consultAdd-form"
			>
				<Row :gutter="32" class="margin0">
					<Col :xs="24" :lg="16" v-if="env !== 'xinhua' && env !== 'shyfs'">
						<FormItem label="请选择咨询类型" prop="category">
							<Select
								v-model="formData.category"
								@on-change="handleChangeCategory"
								placeholder="请选择"
							>
								<Option
									v-for="item in categoryList"
									:key="item.value"
									:value="item.value"
									>{{ item.label }}
								</Option>
							</Select>
						</FormItem>
					</Col>
					<Col
						:xs="24"
						:lg="16"
						v-if="
							env !== 'shyfs' ||
							(env === 'shyfs' && $route.query.category === '0')
						"
					>
						<FormItem
							label="请问您之前是否向CRU咨询过这个研究项目的事宜?"
							prop="isHadConsulted"
						>
							<Select v-model="formData.isHadConsulted" placeholder="请选择">
								<Option key="1" value="1">是</Option>
								<Option key="0" value="0">否</Option>
							</Select>
						</FormItem>
					</Col>
				</Row>
				<Row :gutter="32" class="margin0" v-if="env !== 'xinhua'">
					<Col :xs="24" :lg="8">
						<FormItem
							v-if="formData.category === '0'"
							:label="`预约${getLabel}日期`"
							prop="appointmentDate"
						>
							<DatePicker
								type="date"
								style="width: 100%"
								v-model="formData.appointmentDate"
								@on-change="handleChangeAppointDate"
								format="yyyy-MM-dd"
								:options="dateOptions"
								:placeholder="`预约${getLabel}日期：仅限周三，周四`"
							>
							</DatePicker>
						</FormItem>
						<FormItem
							v-else
							:label="`预约${getLabel}日期`"
							prop="appointmentDate"
						>
							<DatePicker
								type="date"
								style="width: 100%"
								v-model="formData.appointmentDate"
								@on-change="handleChangeAppointDate"
								format="yyyy-MM-dd"
								:options="dateOptions2"
								:placeholder="`预约${getLabel}日期`"
							>
							</DatePicker>
						</FormItem>
					</Col>
					<Col :xs="24" :lg="8">
						<FormItem
							:label="`预约${getLabel}时间段`"
							prop="appointmentTimeSegment"
						>
							<Select
								v-model="formData.appointmentTimeSegment"
								placeholder="请选择"
							>
								<Option
									v-for="(item, index) in timeList"
									:key="index"
									:disabled="!Number(item.value)"
									:value="item.label"
									>{{ item.label }} —— 剩余次数：{{ Number(item.value) }}次
								</Option>
							</Select>
						</FormItem>
					</Col>
				</Row>
				<!--您的资料-->
				<Row :gutter="32" class="margin0">
					<Col span="24" class="title">您的资料</Col>
					<Col :xs="24" :lg="8">
						<FormItem label="姓名" prop="name">
							<Input v-model="formData.name" placeholder="请输入姓名" />
						</FormItem>
					</Col>
					<Col :xs="24" :lg="8">
						<FormItem label="科室" prop="department">
							<Input v-model="formData.department" placeholder="请输入科室" />
						</FormItem>
					</Col>
					<Col :xs="24" :lg="8">
						<FormItem label="手机" prop="phone">
							<Input
								v-model="formData.phone"
								:maxlength="11"
								placeholder="请输入手机"
							/>
						</FormItem>
					</Col>
					<Col :xs="24" :lg="8">
						<FormItem label="邮箱" prop="mail">
							<Input v-model="formData.mail" placeholder="请输入邮箱" />
						</FormItem>
					</Col>
					<Col :xs="24" :lg="8">
						<FormItem label="在研究中您担任" prop="responsibility">
							<Select
								v-model="formData.responsibility"
								filterable
								clearable
								placeholder="请选择"
							>
								<Option
									v-for="item in responsibility"
									:key="item.k"
									:value="item.k"
									>{{ item.v }}
								</Option>
							</Select>
						</FormItem>
					</Col>
					<Col :xs="24" :lg="8" v-if="formData.responsibility === 3885">
						<FormItem label="在研究中您担任，其他" prop="responsibilityInfo">
							<Input
								placeholder="请输入其他内容"
								v-model="formData.responsibilityInfo"
							></Input>
						</FormItem>
					</Col>
				</Row>
				<!--主要研究者的资料-->
				<Row :gutter="32" class="margin0">
					<Col span="24" class="title">主要研究者的资料</Col>
					<Col :xs="24" :lg="8">
						<FormItem label="由您负责这个研究吗?" prop="isResponsible">
							<Select v-model="formData.isResponsible" placeholder="请选择">
								<Option key="1" value="1">是</Option>
								<Option key="0" value="0">否</Option>
							</Select>
						</FormItem>
					</Col>
					<Col :xs="24" :lg="8" v-if="formData.isResponsible === '0'">
						<FormItem label="主要研究者姓名" prop="researcherName">
							<Input
								v-model="formData.researcherName"
								placeholder="请输入主要研究者姓名"
							/>
						</FormItem>
					</Col>
				</Row>
				<!--研究项目的信息-->
				<Row :gutter="32" class="margin0">
					<Col span="24" class="title">研究项目的信息</Col>
					<Col :xs="24" :lg="8">
						<FormItem label="研究题目" prop="researchTopic">
							<Input
								v-model="formData.researchTopic"
								placeholder="请输入研究题目"
							/>
						</FormItem>
					</Col>
					<Col :xs="24" :lg="8">
						<FormItem label="目前研究的阶段" prop="researchStage">
							<Select
								v-model="formData.researchStage"
								filterable
								clearable
								placeholder="请选择"
							>
								<Option
									v-for="item in researchStageList"
									:key="item.k"
									:value="item.k"
									:label="item.v"
								>
								</Option>
							</Select>
						</FormItem>
					</Col>
					<Col :xs="24" :lg="8">
						<FormItem label="研究的伦理情况" prop="ethicalSituation">
							<Select
								v-model="formData.ethicalSituation"
								filterable
								clearable
								placeholder="请选择"
							>
								<Option
									v-for="item in ethicalSituationList"
									:key="item.k"
									:value="item.k"
									:label="item.v"
								>
								</Option>
							</Select>
						</FormItem>
					</Col>
					<Col :xs="24" :lg="8" v-if="formData.ethicalSituation === 3901">
						<FormItem label="其他，研究的伦理情况" prop="ethicalSituationInfo">
							<Input
								placeholder="请输入其他内容"
								v-model="formData.ethicalSituationInfo"
							></Input>
						</FormItem>
					</Col>
					<template v-if="formData.category !== '1'">
						<Col :xs="24" :lg="8">
							<FormItem label="研究类型" prop="researchType">
								<Cascader
									:data="researchTypeList"
									:transfer="true"
									@on-change="handleChangeResearchType"
									v-model="formData.researchType"
								></Cascader>
							</FormItem>
						</Col>
						<!--咨询申请 crc服务 生物样本库 类别时，研究类型为其他时 的备注-->
						<Col
							:xs="24"
							:lg="8"
							v-if="
								formData.researchType && formData.researchType[0] === '3909'
							"
						>
							<FormItem label="其他，研究类型" prop="researchTypeInfo">
								<Input
									placeholder="请输入其他内容"
									v-model="formData.researchTypeInfo"
								></Input>
							</FormItem>
						</Col>
					</template>
					<template v-if="formData.category === '1'">
						<!--眼防所检查平台使用 研究类型 多选-->
						<Col :xs="24" :lg="8">
							<FormItem label="研究类型" prop="researchType">
								<Select
									v-model="formData.researchType"
									filterable
									clearable
									multiple
									placeholder="请选择"
								>
									<Option
										v-for="item in researchTypeList"
										:key="item.value"
										:value="item.value"
										:label="item.label"
									>
									</Option>
								</Select>
							</FormItem>
						</Col>
						<!--检查平台使用 类别，研究类型为其他时 的备注-->
						<Col
							:xs="24"
							:lg="8"
							v-if="
								formData.researchType && formData.researchType.includes('3985')
							"
						>
							<FormItem label="其他，研究类型">
								<Input
									placeholder="请输入其他内容"
									v-model="formData.checkRemark"
								></Input>
							</FormItem>
						</Col>
					</template>
					<Col :xs="24" :lg="8">
						<FormItem label="研究资金情况" prop="financialSituation">
							<Select
								v-model="formData.financialSituation"
								filterable
								clearable
								placeholder="请选择"
							>
								<Option
									v-for="item in financialSituationList"
									:key="item.k"
									:value="item.k"
									:label="item.v"
								>
								</Option>
							</Select>
						</FormItem>
					</Col>
					<Col span="24">
						<FormItem
							required
							:label="
								env !== 'xinhua'
									? '您本次申请的服务为'
									: '本次咨询的主要内容和方向是什么？'
							"
						>
							<ul class="model-sevice">
								<li v-for="(item, index) in cruServiceList" :key="index">
									<template v-if="item.children">
										<h4>{{ item.label }}</h4>
										<CheckboxGroup
											v-model="cruServe[index]"
											@on-change="val => handleChangeSever(val, index, item)"
										>
											<Checkbox
												v-for="child in item.children"
												:key="child.value"
												:label="child.value"
												>{{ child.label }}
												<Input
													v-if="
														child.label === '其他' &&
														cruServe[index] &&
														cruServe[index].includes('3980')
													"
													v-model="formData.provideInstructions"
													:maxlength="20"
													placeholder="请填写备注"
													style="width: 200px"
												></Input
											></Checkbox>
										</CheckboxGroup>
									</template>
									<template v-else>
										<CheckboxGroup
											v-model="cruServe[index]"
											@on-change="val => handleChangeSever(val, index, item)"
										>
											<Checkbox :label="item.value">{{ item.label }}</Checkbox>
										</CheckboxGroup>
										<Input
											v-if="item.label === '生物样本数量'"
											v-model="formData.biologicalAmount"
											placeholder="请填写数量"
											class="remark-input"
											style="left: 120px"
										></Input>
										<Input
											v-if="item.label === '其他'"
											v-model="formData.provideInstructions"
											placeholder="请填写备注"
											class="remark-input"
										></Input>
									</template>
								</li>
							</ul>
						</FormItem>
					</Col>
					<Col :xs="24" :lg="16" v-if="env && env === 'xinhua'">
						<FormItem
							label="在完成这个申请表之前，您是否已经安排了和CRU的会议?"
							prop="meeting"
						>
							<Select v-model="formData.meeting" placeholder="请选择">
								<Option key="1" value="1">是，我已经安排了会议</Option>
								<Option key="0" value="0">否，我需要安排会议</Option>
							</Select>
						</FormItem>
					</Col>
					<Col span="24" v-if="formData.meeting === '1'">
						<FormItem
							label="如果您已经安排了和CRU的会议，请提供CRU人员的姓名"
							prop="meetingParticipants"
						>
							<Input
								v-model="formData.meetingParticipants"
								placeholder="请填写CRU人员姓名"
							></Input>
						</FormItem>
					</Col>
					<Col :span="24">
						<FormItem
							:label="
								env !== 'xinhua'
									? '请您尽可能描述一下本次服务内容'
									: '请您尽可能描述一下咨询的问题'
							"
							prop="problemDescription"
						>
							<Input
								type="textarea"
								v-model="formData.problemDescription"
								:maxlength="500"
								:placeholder="`请输入${env !== 'xinhua' ? '服务内容' : '咨询的问题'}，不超过500字`"
							>
							</Input>
						</FormItem>
					</Col>
					<Col :xs="24" :lg="8" v-if="env && env === 'xinhua'">
						<FormItem label="请您说明需回复日期" prop="needDate">
							<DatePicker
								type="date"
								style="width: 100%"
								:editable="false"
								v-model="formData.needDate"
								@on-change="v => (formData.needDate = v)"
								format="yyyy-MM-dd"
								placeholder="请选择时间"
							>
							</DatePicker>
						</FormItem>
					</Col>
					<Col :span="24">
						<FormItem
							label="上传研究相关文件（不要上传含有受试者个人信息的文件，如受试者的姓名、身份证号码等）"
							prop="files"
						>
							<Upload
								action="/"
								multiple
								:default-file-list="uploadFileList"
								:show-upload-list="true"
								:before-upload="beforeUpload"
								:on-remove="removeFile"
							>
								<Button :loading="loading" type="warning">上传文件</Button>
							</Upload>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</div>
		<div class="drawer-footer">
			<Button v-if="env === 'shyfs'" style="margin-right: 10px" @click="toPrev"
				>上一步
			</Button>
			<Button type="primary" @click="onOk" :loading="loading">提交 </Button>
		</div>
	</div>
</template>

<script>
import api from "@/api/consult/consult"
import publicApi from "@/api/public"
import config from "@/config/options.config"
import { mapState } from "vuex"

const { apiConsultAddWithOutToken, apiAppointmentTimes } = api
const { publicGetEnv } = publicApi
const { categoryList } = config
export default {
	name: "consultAdd",
	data() {
		return {
			loading: false,
			categoryList,
			formData: {
				cruServe: [],
				researchType: [],
				category: "0" // 默认类别是咨询申请
			},
			cruServe: {},
			extraList: [],
			uploadFileList: [],
			dateOptions: {
				disabledDate(date) {
					let result = date && date.valueOf() < Date.now() - 86400000
					if (!result) {
						// 只能选中今天及以后的日期
						result = date.getDay() !== 3 && date.getDay() !== 4 // 只能选 周三和周四
					}
					return result
				}
			},
			dateOptions2: {
				disabledDate(date) {
					// 只能选中今天及以后的日期
					const result = date && date.valueOf() < Date.now() - 86400000
					return result
				}
			},
			timeList: [],
			isOk: false,
			env: ""
		}
	},
	computed: {
		rules() {
			return {
				category: [
					{ required: true, message: "请选择咨询类别", trigger: "change" }
				],
				biologicalAmount: [
					{
						required: false,
						...this.$rules.positiveInteger,
						message: "数量必须为正整数",
						trigger: "blur"
					}
				],
				isHadConsulted: {
					required: this.env && this.env === "xinhua",
					message: "是否咨询过研究项目事宜不能为空",
					trigger: "change"
				},
				name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
				mail: [
					{
						required: this.env && this.env === "xinhua",
						message: "邮箱不能为空",
						trigger: "blur"
					},
					{ type: "email", message: "邮箱格式不正确", trigger: "blur" }
				],
				department: {
					required: true,
					message: "科室不能为空",
					trigger: "blur"
				},
				phone: [
					{ required: true, message: "手机号码不能为空", trigger: "blur" },
					{
						...this.$rules.mobileNumber,
						message: "手机号码格式不正确",
						trigger: "blur"
					}
				],
				responsibility: {
					type: "number",
					required: this.env && this.env === "xinhua",
					message: "担任角色不能为空",
					trigger: "change blur"
				},
				responsibilityInfo: {
					required: this.env && this.env === "xinhua",
					message: "在研究中担任其中情况不能为空",
					trigger: "blur"
				},
				isResponsible: {
					required: this.env && this.env === "xinhua",
					message: "是否是您负责研究不能为空",
					trigger: "change"
				},
				researcherName: {
					required: this.env && this.env === "xinhua",
					message: "研究者姓名不能为空",
					trigger: "blur"
				},
				researchTopic: {
					required: this.env && this.env === "xinhua",
					message: "研究题目不能为空",
					trigger: "blur"
				},
				researchStage: {
					type: "number",
					required: this.env && this.env === "xinhua",
					message: "研究的阶段不能为空",
					trigger: "change blur"
				},
				ethicalSituation: {
					type: "number",
					required: this.env && this.env === "xinhua",
					message: "研究的伦理情况不能为空",
					trigger: "change blur"
				},
				ethicalSituationInfo: {
					required: this.env && this.env === "xinhua",
					message: "研究中的伦理其他情况不能为空",
					trigger: "blur"
				},
				researchType: {
					type: "array",
					required: true,
					message: "研究类型不能为空",
					trigger: "change blur"
				},
				researchTypeInfo: {
					required: true,
					message: "研究类型其他情况不能为空",
					trigger: "blur"
				},
				financialSituation: {
					type: "number",
					required: this.env && this.env === "xinhua",
					message: "研究资金情况不能为空",
					trigger: "change blur"
				},
				provideServiceArr: {
					type: "array",
					required: this.env && this.env === "xinhua",
					message: "请选择您希望CRU提供的服务",
					trigger: "change blur"
				},
				meeting: {
					required: true,
					message: "是否已经安排了和CRU的会议未选择",
					trigger: "change blur"
				},
				meetingParticipants: {
					required: true,
					message: "参与CRU的会议人员不能为空",
					trigger: "blur"
				},
				problemDescription: [
					{
						required: true,
						message:
							this.env !== "xinhua" ? "服务内容不能为空" : "问题描述不能为空",
						trigger: "blur"
					},
					{
						max: 500,
						message: "长度不能超过500字",
						trigger: "blur"
					}
				],
				needDate: {
					required: this.env && this.env === "xinhua",
					message: "回复日期不能为空",
					trigger: "change blur"
				},
				appointmentDate: {
					required: true,
					message: `预约${this.getLabel}日期不能为空`,
					trigger: "change blur"
				},
				appointmentTimeSegment: {
					required: true,
					message: `预约${this.getLabel}时间段不能为空`,
					trigger: "change"
				}
			}
		},
		// 担任的角色
		responsibility() {
			return this.$store.state.enumerate.RESPONSIBILITY
		},
		// 研究阶段
		researchStageList() {
			return this.$store.state.enumerate.RESEARCHSTAGE
		},
		// 研究的伦理情况
		ethicalSituationList() {
			return this.$store.state.enumerate.ETHICALSITUATION
		},
		// 研究类型
		researchTypeList() {
			if (this.formData.category !== "1") {
				return this.$store.state.enumerate.RESEARCHTYPE
			}
			return this.$store.state.enumerate.RESEARCHTYPE_1
		},
		// 资金情况
		financialSituationList() {
			return this.$store.state.enumerate.FINANCIALSITUATION
		},
		// CUR服务
		cruServiceList() {
			let list = []
			if (this.env !== "xinhua") {
				if (this.formData.category === "1") {
					// 检查平台使用模块
					list = this.$store.state.enumerate.CRU_SERVE_1
				} else if (this.formData.category === "2") {
					// CRC服务模块
					list = this.$store.state.enumerate.CRU_SERVE_2
				} else if (this.formData.category === "3") {
					// 生物样本库模块
					list = this.$store.state.enumerate.CRU_SERVE_3
				} else {
					// 咨询申请模块 '0'
					list = this.$store.state.enumerate.CRU_SERVE || []
					list = list.map(item => {
						const obj = { ...item }
						if (item.children && item.children.length) {
							obj.children = item.children.filter(child => {
								if (item.value === "3917" && child.value === "3926") {
									// 项目管理模块 3926 "财务报账流程与准备"
									return false
								}
								if (
									item.value === "3918" &&
									(child.value === "3936" || child.value === "3937")
								) {
									// 方法学支撑模块 3936 "卫生经济学分析" ，3937 "文稿准备"
									return false
								}
								return true
							})
						}
						return obj
					})
				}
			} else {
				list = this.$store.state.enumerate.CRU_SERVE || []
			}
			return list
		},
		...mapState(["enumerate"]),
		titleTxt() {
			const itemC = this.categoryList.find(
				item => item.value === this.$route.query.category
			)
			const numC = Number(this.$route.query.category)
			const txt = numC > 1 ? "预约申请" : numC === 1 ? "检查申请" : itemC.label
			return txt
		},
		getLabel() {
			if (this.env === "shyfs") {
				return this.$route.query.category === "0" ? "咨询" : "申请"
			}
			return "咨询"
		}
	},
	watch: {
		isOk() {
			if (this.isOk) {
				this.$refs.addConsultForm && this.$refs.addConsultForm.resetFields()
				this.uploadFileList = []
				this.timeList = []
				this.formData = {
					cruServe: [],
					researchType: [],
					category: this.env === "shyfs" ? this.$route.query.category : "0" // 默认类别是咨询申请
				}
				this.cruServe = {}
			}
		}
	},
	created() {
		// this.getEnv();
		this.init()
	},
	methods: {
		init() {
			this.loading = true
			// 获取所有枚举
			if (!Object.keys(this.enumerate).length) {
				this.$store.dispatch("getEnumerate")
			}
			setTimeout(() => {
				this.loading = false
			}, 1000)
		},
		async getEnv() {
			// 获取当前登录环境
			this.loading = true
			this.isOk = false
			const res = await publicGetEnv()
			if (res) {
				this.$store.commit("updateState", { environment: res.data })
				sessionStorage.setItem("environment", res.data)
				this.env = res.data
			}
			this.isOk = true
			this.loading = false
		},
		handleChangeCategory(v) {
			this.$set(this.formData, "appointmentDate", "")
			this.$set(this.formData, "appointmentTimeSegment", "")
			this.timeList = []
			this.formData.researchType = []
			this.formData.cruServe = []
			this.formData.checkRemark = ""
			this.formData.researchTypeInfo = ""
			this.cruServe = {}
		},
		handleChangeAppointDate(v) {
			if (v && this.formData.appointmentDate === v) {
				return
			}
			this.$set(this.formData, "appointmentTimeSegment", "")
			this.timeList = []
			if (!v) {
				return
			}
			this.formData.appointmentDate = v
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiAppointmentTimes({
					appointmentDate: this.formData.appointmentDate,
					category: this.formData.category
				})
				if (res) {
					const obj = res.data
					const keys = Object.keys(obj)
					keys.forEach(key => {
						this.timeList.push({
							label: key,
							value: String(obj[key])
						})
					})
				}
				this.loading = false
			})
		},
		handleChangeSever(val, index, item) {
			const Pid = item.children
				? item.value
				: val.length
					? val[0].toString()
					: ""

			if (!Pid) {
				// Pid不存在，说明没有子节点的那个模块，现在取消选中了。
				this.formData.cruServe = this.formData.cruServe.filter(
					dataItem => dataItem.id !== item.value
				)
				return
			}

			const isHave = this.formData.cruServe.find(
				dataItem => dataItem.id === Pid
			)
			if (!isHave) {
				this.formData.cruServe.push({
					id: Pid,
					children: item.children ? val : null
				})
			} else if (item.children) {
				// 有子节点
				if (val.length) {
					this.formData.cruServe = this.formData.cruServe.map(dataItem => {
						if (dataItem.id === Pid) {
							dataItem.children = val
						}
						return dataItem
					})
				} else {
					this.formData.cruServe = this.formData.cruServe.filter(
						dataItem => dataItem.id !== Pid
					)
				}
			}
		},
		handleChangeResearchType(val) {
			this.$set(this.formData, "researchType", val)
		},
		// 上一步
		toPrev() {
			this.$router.push({
				name: "shyfsPage"
			})
		},
		onOk() {
			this.$refs.addConsultForm.validate(valid => {
				if (valid) {
					if (!this.formData.cruServe.length) {
						if (this.env !== "xinhua") {
							this.$Message.error("您本次申请的服务，不能为空")
						} else {
							this.$Message.error("本次咨询的主要内容和方向是什么，不能为空")
						}
						return
					}
					this.$asyncDo(async () => {
						this.loading = true
						const fd = new FormData()
						Object.keys(this.formData).forEach(key => {
							if (key === "cruServe") {
								fd.append("cruServe", JSON.stringify(this.formData.cruServe))
							} else if (key === "researchType") {
								fd.append(
									"researchType",
									JSON.stringify(this.formData.researchType)
								)
							} else {
								fd.append(key, this.formData[key])
							}
						})
						this.uploadFileList.forEach(file => {
							fd.append("files", file)
						})
						const res = await apiConsultAddWithOutToken(fd)
						if (res) {
							this.$router.push({
								name: "consultFillIn",
								query: {
									env: this.env
								}
							})
						}
						this.loading = false
					})
				}
			})
			// return true;
		},
		removeFile(file, fileList) {
			this.uploadFileList = fileList
		},
		beforeUpload(file) {
			this.uploadFileList.push(file)
			return false
		}
	}
}
</script>

<style lang="less">
body {
	margin: 0;
	width: 100%;
	min-width: unset;
	overflow-x: hidden;
	overflow-y: auto;
	box-sizing: border-box;
}

.add-consult-list-wrap {
	.modal-header {
		border-bottom: 1px solid #e8eaec;
		padding: 14px 16px;
		line-height: 1;
	}

	.modal-content {
		padding: 16px;
		font-size: 12px;
		line-height: 1.5;
	}

	.drawer-footer {
		position: relative;
		z-index: 2;
		padding: 20px 18px 60px 18px;
		border-top: 1px solid #e8eaec;
		text-align: center;
		/*.ivu-btn {*/
		/*padding: 10px 75px 11px;*/
		/*}*/
	}

	.consultAdd-form {
		padding-top: 2px;

		.title {
			font-size: 15px;
			font-weight: bold;
			padding: 16px 0;
			border-top: dashed 1px #515a6e;
		}

		.margin0 {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}

		.remark-input {
			position: absolute;
			left: 75px;
			top: 2px;
			width: 65%;
			max-width: 300px;
		}
	}

	.ivu-form .ivu-form-item-label {
		text-align: left;
	}

	.model-sevice {
		list-style: none;
		padding-left: 10px;

		li {
			position: relative;
			line-height: 36px;
			border-bottom: 1px dotted #ccc;

			&:last-of-type {
				border: none;
			}

			h4 {
				color: #515a6e;
			}
		}
	}
}
</style>
